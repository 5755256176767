import { useRef } from "react";
import Filter from "./Filter";
import Item from "./Item";
import Pagination from "../Pagination";
import SearchBar from "./SearchBar";
import useGrantsFilter from "./useGrantsFilter";
import useGrantsQuery from "./useGrantsQuery";

export type Props = {
  locale: Locale;
  categories: GrantsList.Category[];
  strings: GrantsList.Localizations;
  yearsAndMonths: GrantsList.YearsAndMonths;
};

export default function List({
  categories,
  locale,
  strings,
  yearsAndMonths
}: Props) {
  const [state, dispatch] = useGrantsFilter(yearsAndMonths);
  const query = useGrantsQuery(locale, state);

  const scrollRef = useRef<HTMLDivElement>(null);

  const onPageChange = (evt: { selected: number }) => {
    dispatch({ type: "changePage", payload: evt.selected + 1 });
    scrollToGrants();
  };

  const scrollToGrants = () => {
    if (!scrollRef.current || !window) return;

    const elem = scrollRef.current,
      rect = elem.getBoundingClientRect(),
      bodyRect = document.body.getBoundingClientRect(),
      offset = rect.top - bodyRect.top - 120;

    if (window.scrollY > offset) {
      window.scrollTo(0, offset);
    }
  };

  return (
    <div className="grants-list" ref={scrollRef}>
      {!state.search && (
        <Filter
          categories={categories}
          state={state}
          dispatch={dispatch}
          strings={strings}
        />
      )}
      {state.search && (
        <SearchBar state={state} dispatch={dispatch} strings={strings} />
      )}
      <ul className="index">
        {query.data?.grants.map((g) => (
          <Item
            key={g.id}
            strings={strings}
            categories={categories}
            grant={g}
          />
        ))}
      </ul>
      {query.data?.meta.pages > 1 && (
        <Pagination
          hrefBuilder={() => null}
          onPageChange={onPageChange}
          page={state.page}
          pageCount={query.data.meta.pages}
          strings={strings}
        />
      )}
    </div>
  );
}
