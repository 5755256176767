import { useReducer } from "react";

export const initialState = {
  year: null,
  month: null,
  page: 1,
  category: null,
  search: false,
  searchQuery: ""
};

export function monthsForYear(state: GrantsList.State, year: number) {
  return (state.yearsAndMonths[year] || []).sort((a, b) => b - a);
}

function reducer(
  state: GrantsList.State,
  action: GrantsList.Action
): GrantsList.State {
  switch (action.type) {
    case "changeSearchQuery":
      return { ...state, searchQuery: action.payload, page: 1 };
    case "changeCategory":
      return { ...state, category: action.payload, page: 1 };
    case "changeMonth":
      return { ...state, month: action.payload, page: 1 };
    case "changeYear": {
      const months = monthsForYear(state, action.payload);
      const month = months.indexOf(state.month) !== -1 ? state.month : null;
      return { ...state, year: action.payload, month: month, page: 1 };
    }
    case "changePage":
      return { ...state, page: action.payload };
    case "toggleSearch":
      return { ...state, search: !state.search, searchQuery: "" };
  }
}

export default function useGrantsFilter(
  yearsAndMonths: GrantsList.YearsAndMonths
): [GrantsList.State, (action: GrantsList.Action) => void] {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    yearsAndMonths: yearsAndMonths
  });
  return [state, dispatch];
}
