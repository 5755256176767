import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import List, { Props as ListProps } from "./GrantsList/List";
import { primeQuery } from "./GrantsList/useGrantsQuery";

type Props = ListProps & {
  initialData: GrantsList.Response;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Infinity }
  }
});

export default function GrantsList(props: Props) {
  primeQuery(queryClient, props.initialData);

  return (
    <QueryClientProvider client={queryClient}>
      <List {...props} />
    </QueryClientProvider>
  );
}
