import Select from "react-select";
import { monthsForYear } from "./useGrantsFilter";

interface Option<T = string | number> {
  label: string;
  value: T;
}

type Props = {
  categories: GrantsList.Category[];
  state: GrantsList.State;
  dispatch: (action: GrantsList.Action) => void;
  strings: GrantsList.Localizations;
};

function mapOptions(items: number[], blankLabel: string) {
  return [{ value: 0, label: blankLabel }].concat(
    items.map((y) => ({ value: y, label: `${y}` }))
  );
}

function valueOption(options: Option[], value: number) {
  value = value || 0;
  return options.filter((o) => o.value === value)[0];
}

export default function Filter({
  categories,
  state,
  dispatch,
  strings
}: Props) {
  const years = Object.keys(state.yearsAndMonths)
    .map((y) => parseInt(y, 10))
    .sort((a, b) => b - a);

  const initialCategories = categories.filter((c) => c.parent == null);
  const yearOptions = mapOptions(years, strings.all_years);

  const categoryOptions = [{ value: 0, label: strings.all_categories }].concat(
    initialCategories.map((c: GrantsList.Category) => ({
      value: c.id,
      label: c.name
    }))
  );

  const monthOptions = [{ value: 0, label: strings.all_months }].concat(
    monthsForYear(state, state.year).map((m) => ({
      value: m,
      label: strings.month_names[m]
    }))
  );

  const changeCategory = (opt: Option<number>) => {
    dispatch({ type: "changeCategory", payload: opt.value || null });
  };

  const changeMonth = (opt: Option<number>) => {
    dispatch({ type: "changeMonth", payload: opt.value || null });
  };

  const changeYear = (opt: Option<number>) => {
    dispatch({ type: "changeYear", payload: opt.value || null });
  };

  const toggleSearch = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "toggleSearch" });
  };

  return (
    <div className="filter">
      <div className="label">{strings.grants}</div>
      <div className="input year">
        <Select
          options={yearOptions}
          value={valueOption(yearOptions, state.year)}
          onChange={changeYear}
          classNamePrefix="react-select"
        />
      </div>
      <div className="input month">
        <Select
          options={monthOptions}
          value={valueOption(monthOptions, state.month)}
          onChange={changeMonth}
          isDisabled={!state.year}
          classNamePrefix="react-select"
        />
      </div>
      <div className="input category">
        <Select
          options={categoryOptions}
          value={valueOption(categoryOptions, state.category)}
          onChange={changeCategory}
          classNamePrefix="react-select"
        />
      </div>
      <div className="search-toggle">
        <a href="#" onClick={toggleSearch}>
          {strings.search_all_grants}
        </a>
      </div>
    </div>
  );
}
