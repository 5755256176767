import React, { useEffect, useMemo, useState } from "react";
import debounce from "debounce";

type Props = {
  state: GrantsList.State;
  dispatch: (action: GrantsList.Action) => void;
  strings: GrantsList.Localizations;
};

export default function SearchBar({ state, dispatch, strings }: Props) {
  const [query, setQuery] = useState(state.searchQuery);

  useEffect(() => {
    setQuery(state.searchQuery);
  }, [state.searchQuery]);

  const debouncedDispatch = useMemo(() => debounce(dispatch, 250), [dispatch]);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setQuery(value);
    debouncedDispatch({ type: "changeSearchQuery", payload: value });
  };

  const handleToggle = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "toggleSearch" });
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        autoFocus
        name="q"
        placeholder={strings.search_all_grants}
        value={query}
        onChange={handleChange}
      />
      <button type="button" className="close" onClick={handleToggle}>
        Close
      </button>
    </div>
  );
}
