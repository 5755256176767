import { QueryClient, useQuery } from "@tanstack/react-query";
import { initialState } from "./useGrantsFilter";

async function fetchGrants(
  locale: Locale,
  state: GrantsList.State
): Promise<GrantsList.Response> {
  let url = `/${locale}/grants.json?page=${state.page}`;

  if (state.year) {
    url += `&year=${state.year}`;
  }
  if (state.month) {
    url += `&month=${state.month}`;
  }
  if (state.category) {
    url += `&category=${state.category}`;
  }

  const response = await fetch(url);
  return await response.json();
}

async function searchGrants(
  locale: Locale,
  state: GrantsList.State
): Promise<GrantsList.Response> {
  if (!state.searchQuery) {
    return { grants: [], meta: { pages: 1 } };
  }

  const url =
    `/${locale}/grants/search.json?page=${state.page}` +
    `&q=${encodeURIComponent(state.searchQuery)}`;
  const response = await fetch(url);
  return await response.json();
}

function queryKey(
  state: GrantsList.State | GrantsList.QueryKey
): GrantsList.QueryKey {
  const key = { ...state };
  if ("yearsAndMonths" in key) {
    delete key.yearsAndMonths;
  }
  return key;
}

export function primeQuery(
  queryClient: QueryClient,
  data: GrantsList.Response
) {
  queryClient.setQueryData(["grantsList", queryKey(initialState)], data);
}

export default function useGrantsQuery(
  locale: Locale,
  state: GrantsList.State
) {
  return useQuery({
    queryKey: ["grantsList", queryKey(state)],
    queryFn: () =>
      state.search ? searchGrants(locale, state) : fetchGrants(locale, state)
  });
}
